import { CardMedia, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'
import { useAppSelector } from '../../../../store'
import { selectRestaurantDetail } from '../../../../store/restaurantSlice'
import {
  RestaurantImagesMainContainer,
  RestaurantImagesMainImgCard,
  RestaurantImagesMainImgWrapper,
  RestaurantImagesOtherImgsCard,
  RestaurantImagesOtherImgsInnerWrapper,
  RestaurantImagesOtherImgsWrapper,
  RestaurantImagesSkeleton,
  RestaurantImagesSmallScreenImgsCard,
  RestaurantImagesSmallScreenImgsWrapper,
} from '../styled/RestaurantImages.styled'

const RestaurantImages: React.FC = () => {
  const theme = useTheme()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [loaded, setLoaded] = useState(new Array(5).fill(false))

  const imageUrl =
    restaurant?.imageurl && restaurant?.imageurl.length !== 0
      ? restaurant?.imageurl
      : DEFAULT_CARD_IMAGE

  const images = Array(5).fill({
    src: imageUrl,
    alt: 'resactly-restaurant-image',
  })

  const handleImageLoad = (index: number): void => {
    setLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded]
      newLoaded[index] = true
      return newLoaded
    })
  }

  return (
    <>
      {!isSmallScreen ? (
        <RestaurantImagesMainContainer>
          <RestaurantImagesMainImgWrapper>
            <RestaurantImagesMainImgCard>
              {!loaded[0] && <RestaurantImagesSkeleton />}
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: loaded[0] ? 'block' : 'none',
                }}
                component="img"
                image={images[0].src}
                alt={images[0].alt}
                onLoad={() => handleImageLoad(0)}
              />
            </RestaurantImagesMainImgCard>
          </RestaurantImagesMainImgWrapper>
          <RestaurantImagesOtherImgsWrapper>
            {images.slice(1).map((image, index) => (
              <RestaurantImagesOtherImgsInnerWrapper key={index}>
                <RestaurantImagesOtherImgsCard>
                  {!loaded[index + 1] && <RestaurantImagesSkeleton />}
                  <CardMedia
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: loaded[index + 1] ? 'block' : 'none',
                    }}
                    component="img"
                    image={image.src}
                    alt={image.alt}
                    onLoad={() => handleImageLoad(index + 1)}
                  />
                </RestaurantImagesOtherImgsCard>
              </RestaurantImagesOtherImgsInnerWrapper>
            ))}
          </RestaurantImagesOtherImgsWrapper>
        </RestaurantImagesMainContainer>
      ) : (
        <RestaurantImagesSmallScreenImgsWrapper>
          {images.map((image, index) => (
            <RestaurantImagesSmallScreenImgsCard key={index}>
              {!loaded[index] && <RestaurantImagesSkeleton />}
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: loaded[index] ? 'block' : 'none',
                }}
                component="img"
                image={image.src}
                alt={image.alt}
                onLoad={() => handleImageLoad(index)}
              />
            </RestaurantImagesSmallScreenImgsCard>
          ))}
        </RestaurantImagesSmallScreenImgsWrapper>
      )}
    </>
  )
}

export default RestaurantImages
