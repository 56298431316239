import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconMessageCircle } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Filter from '../../../../components/filter/Filter'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { ROUTES } from '../../../../helpers/routes-helper'
import { price } from '../../../../helpers/static-constants'
import { useAppSelector } from '../../../../store'
import { selectGroup } from '../../../../store/socialSlice'
import {
  IFilterResponseItem,
  IFilterTabList,
  RestaurantResponse,
} from '../../../../types'
import {
  GroupHeaderCustomButton,
  GroupHeaderIconAdjustments,
  GroupHeaderIconMessageCircle,
  GroupHeaderLeftContainer,
  GroupHeaderLeftInnerContainer,
  GroupHeaderLeftMainContainerButton,
  GroupHeaderLeftMainContainerIconAdjustments,
  GroupHeaderLeftMainContainerIconChevronLeft,
  GroupHeaderLeftMainContainerIconPointFilled,
  GroupHeaderMainContainer,
  GroupHeaderRightContainer,
  GroupHeaderRoundedButton,
  GroupHeaderSubTitle,
  GroupHeaderTitle,
} from '../styled/GroupHeader.styled'

interface GroupHeaderProps {
  open: boolean
  openChat: React.Dispatch<React.SetStateAction<boolean>>
  headerTitle: string
  badgeContent: number
  onFilter: (item: IFilterResponseItem) => void
  restaurants: RestaurantResponse[]
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  open,
  openChat,
  headerTitle,
  badgeContent,
  onFilter,
  restaurants,
}) => {
  const navigate = useNavigate()
  const group = useAppSelector(selectGroup)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [filterTabList, setFilterTabList] = useState<IFilterTabList[]>([])
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)

  const onFilterItems = (): IFilterTabList[] => {
    const filterItems = [
      {
        key: 1,
        name: 'Distance',
        items: [
          { key: 1, name: '0 mi', value: '0' },
          { key: 2, name: '5 mi', value: '5' },
          { key: 3, name: '10 mi', value: '10' },
          { key: 4, name: '15 mi', value: '15' },
          { key: 5, name: '20 mi', value: '20' },
          { key: 6, name: '25 mi', value: '25' },
          { key: 7, name: '30 mi', value: '30' },
        ],
        rangeStep: 5,
        filterType: 'range',
      },
    ].filter(Boolean) as IFilterTabList[]

    if (restaurants && restaurants.length !== 0) {
      const allDietaryPreferences = restaurants.flatMap(
        (item) => item.restaurantDTO.dietary,
      )
      const uniqueDietaryPreferences = Array.from(
        new Set(
          allDietaryPreferences.filter(
            (preference): preference is string => preference !== undefined,
          ),
        ),
      )

      const cuisineList: string[] = restaurants
        .map((item) => item.restaurantDTO.cuisine)
        .filter((cuisine): cuisine is string => cuisine !== undefined)

      const priceRanges = restaurants.map(
        (dish) => dish.restaurantDTO.priceRange,
      )
      const uniquePriceRanges = Array.from(new Set(priceRanges))

      if (uniquePriceRanges.length > 1) {
        filterItems.push({
          key: 2,
          name: 'Price',

          items: Array.from({ length: 4 }).map((_, index) => {
            return {
              key: index + 1,
              name: price[index],
              value: `${index + 1}`,
            }
          }),
          rangeStep: 1,
          filterType: 'range',
          priceType: 'dollar',
        })
      }
      if (uniqueDietaryPreferences.length !== 0) {
        filterItems.push({
          key: 3,
          name: 'Dietary',
          items: uniqueDietaryPreferences.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
      if (cuisineList.length !== 0) {
        filterItems.push({
          key: 4,
          name: 'Cuisine',
          items: cuisineList.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
    }

    return filterItems
  }

  useEffect(() => {
    if (restaurants && restaurants.length) {
      const filter = onFilterItems()
      setFilterTabList(filter)
    }
  }, [restaurants])

  const SelectedItemCount = (): JSX.Element => {
    return (
      // TODO: STYLE_TODO: convert in styled component (GroupHeaderBadgeTypography)
      <Typography
        color="white"
        component="p"
        sx={{
          width: badgeContent > 99 ? '25px' : '17px',
          height: badgeContent > 99 ? '18px' : '17px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        variant="caption"
        bgcolor={theme.palette.secondary.main}
      >
        {badgeContent}
      </Typography>
    )
  }

  return (
    <>
      <GroupHeaderMainContainer>
        <GroupHeaderLeftContainer>
          <GroupHeaderRoundedButton
            diameter={32}
            borderColor="transparent"
            onClick={() => navigate(ROUTES.SOCIAL)}
          >
            <GroupHeaderLeftMainContainerIconChevronLeft />
          </GroupHeaderRoundedButton>
          <Box>
            <GroupHeaderTitle>{headerTitle}</GroupHeaderTitle>
            <GroupHeaderLeftInnerContainer>
              <GroupHeaderSubTitle>{group.searchTerm}</GroupHeaderSubTitle>
              <GroupHeaderLeftMainContainerIconPointFilled />
              <GroupHeaderSubTitle>{group.location}</GroupHeaderSubTitle>
            </GroupHeaderLeftInnerContainer>
          </Box>
        </GroupHeaderLeftContainer>
        <GroupHeaderRightContainer>
          <>
            {isSmallScreen ? (
              <RoundedButton
                component="p"
                borderColor={theme.palette.grey[800]}
                diameter={37}
                onClick={() => setFilterModalOpen(true)}
              >
                <GroupHeaderLeftMainContainerIconAdjustments />
              </RoundedButton>
            ) : (
              <>
                <Box>
                  <Filter
                    onFilterData={onFilter}
                    tabList={filterTabList}
                    rowData={restaurants}
                    isFilterModalOpen={filterModalOpen}
                    setFilterModalOpen={setFilterModalOpen}
                  />
                </Box>
                <GroupHeaderLeftMainContainerButton
                  startIcon={<GroupHeaderIconAdjustments />}
                  onClick={() => setFilterModalOpen(true)}
                >
                  Filter
                </GroupHeaderLeftMainContainerButton>
                {!open && (
                  <GroupHeaderCustomButton
                    title="Messages"
                    textVariant="subtitle1"
                    onClick={() => openChat(true)}
                    icon={
                      <>
                        {badgeContent > 0 && <SelectedItemCount />}
                        <GroupHeaderIconMessageCircle />
                      </>
                    }
                  />
                )}
              </>
            )}
          </>
        </GroupHeaderRightContainer>
      </GroupHeaderMainContainer>
      {isSmallScreen && (
        <Box mt="12px">
          <Filter
            onFilterData={onFilter}
            tabList={filterTabList}
            rowData={restaurants}
            isFilterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />
        </Box>
      )}
      {isSmallScreen && !open && (
        <GroupHeaderCustomButton
          title="Messages"
          textVariant="subtitle1"
          onClick={() => openChat(true)}
          icon={
            <>
              {badgeContent > 0 && <SelectedItemCount />}
              <IconMessageCircle size={18} stroke={2} />
            </>
          }
        />
      )}
    </>
  )
}

export default GroupHeader
