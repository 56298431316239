import { useAuth0 } from '@auth0/auth0-react'
import { useMediaQuery, useTheme } from '@mui/material'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Mousewheel, Navigation } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import RestaurantCard from '../../components/restaurant/RestaurantCard'
import { ROUTES } from '../../helpers/routes-helper'
import { DEFAULT_CARD_IMAGE } from '../../helpers/static-constants'
import { MuiAlert, MuiSnackbar } from '../../pages/eater/styled/global.styled'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../store/restaurantSlice'
import {
  ILandingRestaurantList,
  LandingType,
  LandingTypeValue,
} from '../../types'
import RestaurantCardContainerSkeleton from '../skeleton/RestaurantCardContainerSkeleton'
import {
  RestaurantCardContainerNoDataTypography,
  RestaurantCardContainerSwiperContainer,
  RestaurantCardContainerTopBarContainer,
  RestaurantCardContainerTopBarInnerContainer,
  RestaurantCardContainerTopBarTypography,
  RestaurantCardContainerTopBarViewAllTypography,
} from '../styled/restaurant/RestaurantCardContainer.styled'
import ImgIcon from '../ui/ImgIcon'

interface RestaurantCardContainerProps {
  title: string
  cardList: ILandingRestaurantList | undefined
  loading: boolean
  name: LandingType
}

const RestaurantCardContainer: React.FC<RestaurantCardContainerProps> = ({
  title,
  cardList,
  loading = false,
  name,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const swiperRef = useRef<SwiperRef>(null)
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const dispatch = useAppDispatch()
  const { isAuthenticated, isLoading } = useAuth0()
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handlePrev = (): void => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = (): void => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const handleSlideChange = (swiper: any): void => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const FallbackMessage = useCallback(
    () => (
      <div>
        <RestaurantCardContainerNoDataTypography>
          We cannot find any {LandingTypeValue[name]} with your filters
        </RestaurantCardContainerNoDataTypography>
      </div>
    ),
    [cardList],
  )

  return (
    <div>
      <RestaurantCardContainerTopBarContainer>
        <div>
          <RestaurantCardContainerTopBarTypography>
            {title}
          </RestaurantCardContainerTopBarTypography>
        </div>
        {!loading && cardList && cardList.restaurants.length > 0 && (
          <RestaurantCardContainerTopBarInnerContainer>
            <RestaurantCardContainerTopBarViewAllTypography>
              View All
            </RestaurantCardContainerTopBarViewAllTypography>
            {!isSmallScreen && (
              <>
                <ImgIcon
                  iconElement={<IconChevronLeft stroke={2} />}
                  alt="left-swipe-restaurant"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.secondary.contrastText,
                    height: '36px',
                    width: '36px',
                    outline: `2px solid ${theme.palette.grey[200]}`,
                    marginRight: '10px',
                    cursor: isBeginning ? 'not-allowed' : 'pointer',
                    opacity: isBeginning ? 0.5 : 1,
                  }}
                  onClick={isBeginning ? undefined : handlePrev}
                />
                <ImgIcon
                  iconElement={<IconChevronRight stroke={2} />}
                  alt="right-swipe-restaurant"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.secondary.contrastText,
                    height: '36px',
                    width: '36px',
                    outline: `2px solid ${theme.palette.grey[200]}`,
                    cursor: isEnd ? 'not-allowed' : 'pointer',
                    opacity: isEnd ? 0.5 : 1,
                  }}
                  onClick={isEnd ? undefined : handleNext}
                />
              </>
            )}
          </RestaurantCardContainerTopBarInnerContainer>
        )}
      </RestaurantCardContainerTopBarContainer>

      {!loading && !isLoading ? (
        <div style={{ paddingTop: '6px' }}>
          {cardList &&
          (cardList?.predictedRestaurants?.length > 0 ||
            cardList?.restaurants?.length > 0) ? (
            <Swiper
              ref={swiperRef}
              slidesPerView={3.4}
              spaceBetween={10}
              navigation={false}
              mousewheel={false}
              modules={[Mousewheel, Navigation]}
              onSlideChange={handleSlideChange}
              className="mySwiper"
              grabCursor={true}
              breakpoints={swiperBreakPoints}
            >
              {isAuthenticated ? (
                cardList.predictedRestaurants &&
                cardList.predictedRestaurants.length > 0 ? (
                  cardList.predictedRestaurants.map((card, index: number) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                      <RestaurantCardContainerSwiperContainer
                        onClick={() =>
                          navigate(
                            `${ROUTES.RESTAURANT_DETAILS}/${card?.restaurantDTO?.alias}/details`,
                          )
                        }
                      >
                        <RestaurantCard
                          id={card?.restaurantDTO?.restaurantID}
                          alias={card?.restaurantDTO?.alias}
                          imgList={[
                            card?.restaurantDTO.imageurl ??
                            card?.restaurantDTO.imageurl !== ''
                              ? card?.restaurantDTO?.imageurl ??
                                DEFAULT_CARD_IMAGE
                              : DEFAULT_CARD_IMAGE,
                          ]}
                          title={card?.restaurantDTO?.name}
                          subTitle={card?.restaurantDTO?.description ?? ''}
                          distance={`${card?.restaurantDTO?.distance ?? 0}`}
                          tagChipBgColor={'orange'}
                          isVerified={card?.restaurantDTO?.verified ?? false}
                          tagChipData={[
                            {
                              percentage: `${card?.recommendation?.yes.toFixed(
                                2,
                              )}`,
                              title: 'Match',
                            },
                          ]}
                        />
                      </RestaurantCardContainerSwiperContainer>
                    </SwiperSlide>
                  ))
                ) : (
                  <FallbackMessage />
                )
              ) : cardList.restaurants && cardList.restaurants.length > 0 ? (
                cardList.restaurants.map((card, index: number) => (
                  <SwiperSlide key={index} virtualIndex={index}>
                    <RestaurantCardContainerSwiperContainer
                      onClick={() =>
                        navigate(
                          `${ROUTES.RESTAURANT_DETAILS}/${card?.alias}/details`,
                        )
                      }
                    >
                      <RestaurantCard
                        id={card?.restaurantID}
                        alias={card?.alias}
                        imgList={[
                          card?.imageurl ?? card?.imageurl !== ''
                            ? card?.imageurl ?? DEFAULT_CARD_IMAGE
                            : DEFAULT_CARD_IMAGE,
                        ]}
                        title={card?.name}
                        subTitle={card?.description ?? ''}
                        distance={`${card?.distance ?? 0}`}
                        isVerified={card?.verified ?? false}
                      />
                    </RestaurantCardContainerSwiperContainer>
                  </SwiperSlide>
                ))
              ) : (
                <FallbackMessage />
              )}
            </Swiper>
          ) : (
            <FallbackMessage />
          )}
        </div>
      ) : (
        <RestaurantCardContainerSkeleton />
      )}
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </div>
  )
}

export default RestaurantCardContainer

const swiperBreakPoints = {
  '0': {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  '336': {
    slidesPerView: 1.1,
    spaceBetween: 5,
  },
  '370': {
    slidesPerView: 1.2,
    spaceBetween: 5,
  },
  '410': {
    slidesPerView: 1.3,
    spaceBetween: 5,
  },
  '430': {
    slidesPerView: 1.4,
    spaceBetween: 5,
  },
  '470': {
    slidesPerView: 1.6,
    spaceBetween: 5,
  },
  '500': {
    slidesPerView: 1.7,
    spaceBetween: 5,
  },
  '530': {
    slidesPerView: 1.8,
    spaceBetween: 5,
  },
  '570': {
    slidesPerView: 1.9,
    spaceBetween: 5,
  },
  '600': {
    slidesPerView: 2,
    spaceBetween: 5,
  },
  '630': {
    slidesPerView: 2.1,
    spaceBetween: 5,
  },
  '670': {
    slidesPerView: 2.2,
    spaceBetween: 5,
  },
  '710': {
    slidesPerView: 2.4,
    spaceBetween: 5,
  },
  '740': {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  '770': {
    slidesPerView: 2.6,
    spaceBetween: 5,
  },
  '800': {
    slidesPerView: 2.7,
    spaceBetween: 5,
  },
  '845': {
    slidesPerView: 2.8,
    spaceBetween: 5,
  },
  '890': {
    slidesPerView: 3,
    spaceBetween: 5,
  },
  '930': {
    slidesPerView: 3.2,
    spaceBetween: 5,
  },
  '960': {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  '975': {
    slidesPerView: 2.6,
    spaceBetween: 10,
  },
  '1023': {
    slidesPerView: 2.7,
    spaceBetween: 10,
  },
  '1090': {
    slidesPerView: 2.8,
    spaceBetween: 5,
  },
  '1130': {
    slidesPerView: 3,
    spaceBetween: 5,
  },
  '1200': {
    slidesPerView: 3.2,
    spaceBetween: 5,
  },
  '1250': {
    slidesPerView: 3.4,
    spaceBetween: 5,
  },
}
